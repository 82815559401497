import { IsCyo, MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { availability, nationalData } from './nationalDataHelpers';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const dealsData = (): MenuRecipe[] => {
  const baseDeals = [
    {
      id: '3',
      name: '$6.99 NEW Pizza Hut Melts',
      productDescription: "Our signature Thin 'N Crispy® crust loaded with toppings and cheese, folded over and baked to melty perfection, then sprinkled with a parmesan and oregano blend. Comes with sauce for dipping, no substitutions.",
      imageURL: '/images/nationalMenu/deals/Deals_Melts.jpg',
      outOfStock: false,
      priority: 2,
      vegetarian: false,
      staticLink: '#',
      imageAltText: '',
      hidden: false,
      availability,
      isCYO: IsCyo.FALSE
    }, {
      id: '4',
      name: 'Big Dinner Box',
      productDescription: 'Feeding the family just got a whole lot easier. Get 2 medium 1-topping pizzas, 5 breadsticks and your choice of pasta or wings. Or skip the sides and get 3 medium 1-topping pizzas.',
      imageURL: '/images/nationalMenu/deals/Deals_BDB.jpg',
      outOfStock: false,
      priority: 3,
      vegetarian: false,
      staticLink: '#',
      imageAltText: '',
      hidden: false,
      availability,
      isCYO: IsCyo.FALSE
    }, {
      id: '5',
      name: 'Dinner Box',
      productDescription: 'Don’t know what to get? We\'ve made it easy. Get a medium 1-topping pizza, 5 breadsticks with marinara and 10 cinnamon sticks with icing—all in one convenient box.',
      imageURL: '/images/nationalMenu/deals/Deals_DinnerBox.jpg',
      outOfStock: false,
      priority: 4,
      vegetarian: false,
      staticLink: '#',
      imageAltText: '',
      hidden: false,
      availability,
      isCYO: IsCyo.FALSE
    }, {
      id: '6',
      name: 'Cinnabon® Mini Rolls',
      productDescription: '10 mini cinnamon rolls, topped with signature cream cheese frosting, are the perfect way to end pizza night. Or you can eat \'em first. Don\'t worry, we won\'t tell.',
      imageURL: '/images/nationalMenu/deals/Deals_Cinnabon.jpg',
      outOfStock: false,
      priority: 5,
      vegetarian: false,
      staticLink: '#',
      imageAltText: '',
      hidden: false,
      availability,
      isCYO: IsCyo.FALSE
    }, {
      id: '7',
      name: 'PEPSI-COLA® 4-Pack',
      productDescription: 'All that\'s missing are the classic Pizza Hut cups. Get four refreshing, individual sized beverages to wash your meal down.',
      imageURL: 'https://www.pizzahut.com/assets/w/tile/thor/deals_pepsi4pack_175x203.png',
      outOfStock: false,
      priority: 6,
      vegetarian: false,
      staticLink: '#',
      imageAltText: '',
      hidden: false,
      availability,
      isCYO: IsCyo.FALSE
    }
  ];

  const dealLoversMenuData = {
    id: '0',
    name: 'Deal Lover\'s™ Menu',
    productDescription: 'Treat yourself to 2 or more of your faves for $7 each - 7 days a week. Product avail., prices & partic. vary. Add’l. Charges may apply. Excl. apply.',
    imageURL: '/images/nationalMenu/deals/Deals_7DealLovers.jpg',
    outOfStock: false,
    priority: 1,
    vegetarian: false,
    staticLink: '#',
    imageAltText: '',
    hidden: false,
    availability,
    isCYO: IsCyo.FALSE
  };

  const myHutBoxDealMenuData = {
    id: '2',
    name: 'NEW! My Hut Box - Make it yours with choice of entrée & side',
    productDescription: 'Choose between Melts or a 2-topping Personal Pan Pizza®, then add a side of fries or 4 boneless wings. Don’t forget the 20oz drink.',
    imageURL: 'https://www.pizzahut.com/assets/w/tile/thor/MHB_Maindealtile_175x203.jpg',
    outOfStock: false,
    priority: 2,
    vegetarian: false,
    staticLink: '#',
    imageAltText: '',
    hidden: false,
    availability,
    isCYO: IsCyo.FALSE
  };

  const bnyDealData = {
    id: '1',
    name: 'The Big New Yorker Pizza',
    productDescription: 'An iconic 16’’ New York-inspired pizza with 6 XL foldable slices, sweet marinara & cheesy garlic Hut Dust seasoning on top. Additional charge for more than 1 topping and extra cheese.',
    imageURL: '/images/nationalMenu/deals/Deals_BNY.jpg',
    outOfStock: false,
    priority: 0,
    vegetarian: false,
    staticLink: '#',
    imageAltText: '',
    hidden: false,
    availability,
    isCYO: IsCyo.FALSE
  };

  return nationalData([bnyDealData, dealLoversMenuData, myHutBoxDealMenuData], baseDeals);
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
export default dealsData;
